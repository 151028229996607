export const state = () => ({
  email: '',
  password: '',
  gender: 'N',
  pseudo: '',
  firstname: '',
  lastname: '',
  picture: null,
  companyName: '',
  companyID: '',
  sso: false,
  incomplete: false,
  phone: '',
  country: {
    isoCode: 'FR',
    countryCode: 33
  },
});

export const mutations = {
  email(state, {value}) {
    state.email = value;
  },
  password(state, {value}) {
    state.password = value;
  },
  gender(state, {value}) {
    state.gender = value;
  },
  pseudo(state, {value}) {
    state.pseudo = value;
  },
  lastname(state, {value}) {
    state.lastname = value;
  },
  firstname(state, {value}) {
    state.firstname = value;
  },
  picture(state, {value}) {
    state.picture = value;
  },
  companyName(state, {value}) {
    state.companyName = value;
  },
  companyID(state, {value}) {
    state.companyID = value;
  },
  phone(state, {value}) {
    state.phone = value;
  },
  country(state, value) {
    state.country = value;
  },
  sso(state, {value}) {
    state.sso = value;
  },
  incomplete(state, {value}) {
    state.incomplete = value;
  },
  reset(state) {
    state.email = '';
    state.password = '';
    state.gender = 'N';
    state.pseudo = '';
    state.firstname = '';
    state.lastname = '';
    state.picture = null;
    state.companyName = '';
    state.companyID = '';
    state.phone = '';
    state.country = {
      isoCode: 'FR',
      countryCode: 33
    };
    state.sso = false;
    state.incomplete = false;
  }
}

export const getters = {
  email(state) {
    return state.email;
  },
  password(state) {
    return state.password;
  },
  gender(state) {
    return state.gender;
  },
  pseudo(state) {
    return state.pseudo;
  },
  lastname(state) {
    return state.lastname;
  },
  firstname(state) {
    return state.firstname;
  },
  picture(state) {
    return state.picture;
  },
  companyName(state) {
    return state.companyName;
  },
  companyID(state) {
    return state.companyID;
  },
  phone(state) {
    return state.phone;
  },
  country(state) {
    return state.country;
  },
  sso(state) {
    return state.sso;
  },
  incomplete(state) {
    return state.incomplete;
  },
}
