export const state = () => ({
  list: null,
  multiple: false,
  property: null,
  mutation: null,
  label: null,
  hint: null,
  search: false,
  type: null,
  infos: null,
  min: null,
  max: null,
  forceSize: null,
  hasCancel: null,
  busId: null,
  value: null,
  fullList: null,
  itemTemplate: null,
  hasFooter: undefined,
  countable: null
});

export const mutations = {
  hasCancel(state, value) {
    state.hasCancel = value;
  },
  list(state, value) {

    /*if (value && Array.isArray(value)) {
      value.forEach(filter => {
        if (filter.children) {
          try {
          filter.children.sort((a, b) => {
            if (a.position && !isNaN(a.position) && b.position && !isNaN(b.position)) {
              return a.position < b.position ? -1 : 1;
            }
            return 0;
          });
          }catch(e) {
            window['console'].log('sort error',e)
          }
        }
      });
    }*/

    state.list = value;
  },
  label(state, value) {
    state.label = value;
  },
  hint(state, value) {
    state.hint = value;
  },
  multiple(state, value) {
    state.multiple = value;
  },
  search(state, value) {
    state.search = value;
  },
  property(state, value) {
    state.property = value;
  },
  mutation(state, value) {
    state.mutation = value;
  },
  type(state, value) {
    state.type = value;
  },
  infos(state, value) {
    state.infos = value;
  },
  max(state, value) {
    state.max = value;
  },
  min(state, value) {
    state.min = value;
  },
  forceSize(state, value) {
    state.forceSize = value;
  },
  busId(state, value) {
    state.busId = value;
  },
  value(state, value) {
    state.value = value;
  },
  fullList(state, value) {
    state.fullList = value;
  },
  itemTemplate(state, value) {
    state.itemTemplate = value;
  },
  hasFooter(state, value) {
    state.hasFooter = value;
  },
  countable(state, value) {
    state.countable = value;
  },
  reset(state) {
    state.busId = null;
    state.list = null;
    state.multiple = false;
    state.property = null;
    state.mutation = null;
    state.label = null;
    state.hint = null;
    state.search = false;
    state.type = null;
    state.infos = null;
    state.min = null;
    state.max = null;
    state.forceSize = null;
    state.hasCancel = null;
    state.value = null;
    state.fullList = null;
    state.itemTemplate = null;
    state.hasFooter = undefined;
    state.countable = null;
  }
}

export const getters = {
  list(state) {
    return state.list;
  },
  hasCancel(state) {
    return state.hasCancel;
  },
  label(state) {
    return state.label;
  },
  hint(state) {
    return state.hint;
  },
  countable(state) {
    return state.countable;
  },
  multiple(state) {
    return state.multiple;
  },
  property(state) {
    return state.property;
  },
  mutation(state) {
    return state.mutation;
  },
  search(state) {
    return state.search;
  },
  type(state) {
    return state.type;
  },
  infos(state) {
    return state.infos;
  },
  min(state) {
    return state.min;
  },
  max(state) {
    return state.max;
  },
  forceSize(state) {
    return state.forceSize;
  },
  busId(state) {
    return state.busId;
  },
  value(state) {
    return state.value;
  },
  itemTemplate(state) {
    return state.itemTemplate;
  },
  hasFooter(state) {
    return state.hasFooter;
  },
  fullList(state) {
    return state.fullList;
  }
}
