export const state = () => ({
  component: null,
  callback: null,
  size: 'big',
  type: 'default',
  data: null,
  busId: null,
  forceHeight : false,
  clickOutside : true,
});

export const mutations = {
  clickOutside(state, value) {
    state.clickOutside = value;
  },
  forceHeight(state, value) {
    state.forceHeight = value;
  },
  component(state, value) {
    state.component = value;
  },
  callback(state, value) {
    state.callback = value;
  },
  size(state, value) {
    state.size = value;
  },
  type(state, value) {
    state.type = value;
  },
  data(state, value) {
    state.data = value;
  },
  busId(state, value) {
    state.busId = value;
  },
  reset(state) {
    state.component = null;
    state.callback = null;
    state.size = 'big';
    state.data = null;
    state.busId = null;
    state.type = 'default';
  }
}

export const getters = {
  clickOutside(state) {
    return state.clickOutside;
  },
  component(state) {
    return state.component;
  },
  callback(state) {
    return state.callback;
  },
  forceHeight(state) {
    return state.forceHeight;
  },
  size(state) {
    return state.size;
  },
  type(state) {
    return state.type;
  },
  data(state) {
    return state.data;
  },
  busId(state) {
    return state.busId;
  }
}
