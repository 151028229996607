export const state = () => ({
  showCookiePopin: false
});

export const mutations = {
  setShowCookiePopin(state, value) {
    state.showCookiePopin = value;
  },
}

export const getters = {
  getShowCookiePopin: state => state.showCookiePopin,
}

export const actions = {
  async initCookies({commit}, value) {
    await commit('setShowCookiePopin', value);
  },
}


