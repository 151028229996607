export const state = () => ({
  searchNavHistory: [],
  searchStatus: false
});

export const mutations = {
  setSearchNavHistory(state, value) {
    state.searchNavHistory = value;
  },
  setSearchStatus(state, value) {
    state.searchStatus = value;
  },
  popSearchNavHistory(state) {
    state.searchNavHistory.pop();
  },
}

export const actions = {
  cleanSearchNavHistory({ commit }) {
    commit('setSearchNavHistory', [])
  },
  popSearchNavHistory({ commit }) {
    commit('popSearchNavHistory')
  },
}

export const getters = {
  getSearchStatus: state => state.searchStatus,
  getSearchNavHistory: state => state.searchNavHistory,
}
