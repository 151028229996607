import {PAYMENT_TYPE} from "assets/js/constants/constant";

export const state = () => ({
  cards: [],
  newCards: []
})

export const mutations = {
  cards(state, value) {
    state.cards = value;
  },
  newCards(state, value) {
    state.newCards = value;
  },
}

export const getters = {
  cards(state) {
    return state.cards;
  },
  paymentsMethods(state) {
    return [...state.cards];
  },
  newCards(state) {
    return state.newCards;
  },
}

export const actions = {
  async refresh({state, commit, getters}, card) {

    if (card) {
      const newCards = getters.newCards;

      await commit('newCards', [...newCards, card]);
    }

    const cardList = await this.$api.$get(process.env.apiUrl + '/api/v1/banking/cards');

    let cards;
    if (cardList && !cardList.error) {
      cards = cardList;
    } else {
      cards = []
    }

    await commit('cards', cards.map(card => ({
      ...card,
      paymentType: PAYMENT_TYPE.card
    })));

    return getters.paymentsMethods;
  },

  async removeCards({commit, getters}) {

    const newCards = getters.newCards;

    newCards?.filter(card => !card.save).forEach(card => {
      this.$api.$post(process.env.apiUrl + '/api/v1/banking/card_status', {
        cardId: card.id
      })
    })

    await commit('newCards', []);
  }
}
