import {getLocalisedTransporters, getTransporterPrice} from "../assets/js/utils/transporters";
import {getProductServicesForTransporters} from "assets/js/utils/product";
import {getCatalogPath} from "assets/js/utils/catalog";
import {COCOLIS_TRANSPORTER, HAND_TO_HAND_TRANSPORTER} from "assets/js/constants/constant";

export const state = () => ({
  name: '',
  description: '',
  state: null,
  price: '',
  autoPrice: false,
  authorizeOffer: false,
  epiMaxDate: false,
  epiNoDamage: false,
  isUnderGuarantee: false,
  isNeverUse: false,
  isPerfectState: false,
  originalPrice: '',
  gender: null,
  category: null,
  tags: null,
  sizes: null,
  colors: [],
  package: null,
  transporters: null,
  metas: {},
  numberOfUse: null,
  brand: null,
  files: null,
  filesRotations: null,
  address: null
});

export const mutations = {
  files(state, value) {
    state.files = value;
  },
  filesRotations(state, value) {
    state.filesRotations = value;
  },
  name(state, value) {
    state.name = value;
  },
  description(state, value) {
    state.description = value;
  },
  autoPrice(state, value) {
    state.autoPrice = value;
  },
  authorizeOffer(state, value) {
    state.authorizeOffer = value;
  },
  epiMaxDate(state, value) {
    state.epiMaxDate = value;
  },
  epiNoDamage(state, value) {
    state.epiNoDamage = value;
  },
  isUnderGuarantee(state, value) {
    state.isUnderGuarantee = value;
  },
  isNeverUse(state, value) {
    state.isNeverUse = value;
  },
  isPerfectState(state, value) {
    state.isPerfectState = value;
  },
  state(state, {value}) {
    state.state = value;
  },
  package(state, {value}) {
    state.package = value;
  },
  transporters(state, {value}) {
    state.transporters = value;
  },
  tags(state, {value}) {
    state.tags = value;
  },
  price(state, value) {
    state.price = value;
  },
  originalPrice(state, value) {
    state.originalPrice = value;
  },
  gender(state, {value}) {
    state.gender = value;
  },
  category(state, {value}) {
    state.category = value;
  },
  sizes(state, {value}) {
    state.sizes = value;
  },
  colors(state, {value}) {
    state.colors = value;
  },
  metas(state, {value, property}) {
    const metas = {...state.metas};
    if (value) {
      metas[property] = value;
    } else {
      delete metas[property];
    }
    state.metas = metas;
  },
  resetMetas(state) {
    state.metas = {};
  },
  brand(state, {value}) {
    state.brand = value;
  },
  address(state, value) {
    state.address = value;
  },
  numberOfUse(state, value) {
    state.numberOfUse = value;
  },
  resetSoft(state) {
    state.state = null;
    state.gender = null;
    state.tags = null;
    state.sizes = null;
    state.colors = [];
    state.package = null;
    state.metas = {};
    state.brand = null;
  },
  reset(state) {
    state.name = '';
    state.description = '';
    state.state = null;
    state.price = '';
    state.gender = null;
    state.category = null;
    state.tags = null;
    state.sizes = null;
    state.colors = [];
    state.package = null;
    state.transporters = null;
    state.metas = {};
    state.brand = null;
    state.files = null;
    state.filesRotations = null;
    state.address = null;
    state.originalPrice = '';
    state.autoPrice = false;
    state.authorizeOffer = false;
    state.epiMaxDate = false;
    state.epiNoDamage = false;
    state.isUnderGuarantee = false;
    state.isNeverUse = false;
    state.isPerfectState = false;
    state.numberOfUse = null
  },
}

export const getters = {
  files(state) {
    return state.files;
  },
  filesRotations(state) {
    return state.filesRotations;
  },
  name(state) {
    return state.name;
  },
  description(state) {
    return state.description;
  },
  state(state) {
    return state.state;
  },
  package(state) {
    return state.package;
  },
  transporters(state) {
    return state.transporters;
  },
  tags(state) {
    return state.tags;
  },
  price(state) {
    return state.price;
  },
  autoPrice(state) {
    return state.autoPrice;
  },
  epiNoDamage(state) {
    return state.epiNoDamage;
  },
  epiMaxDate(state) {
    return state.epiMaxDate;
  },
  isUnderGuarantee(state) {
    return state.isUnderGuarantee;
  },
  isNeverUse(state) {
    return state.isNeverUse;
  },
  isPerfectState(state) {
    return state.isPerfectState;
  },
  originalPrice(state) {
    return state.originalPrice;
  },
  authorizeOffer(state) {
    return state.authorizeOffer;
  },
  gender(state) {
    return state.gender;
  },
  category(state) {
    return state.category;
  },
  sizes(state) {
    return state.sizes;
  },
  colors(state) {
    return state.colors;
  },
  metas(state) {
    return state.metas;
  },
  brand(state) {
    return state.brand;
  },
  address(state) {
    return state.address;
  },
  numberOfUse(state) {
    return state.numberOfUse;
  },
}

export const actions = {
    async getProductTechnicals({dispatch, commit, rootGetters, rootState}, {product, i18n}) {
      let technicals = [];

      let lastTech = null;

      product.metas.forEach(meta => {
        const parent = rootGetters["config/getConfig"].metas.find(parent => parent.children.some(childMeta => childMeta.id === meta.id));
        const alreadyExist = lastTech === parent.name;

        if (alreadyExist) {
          const found = technicals.find(el => el.label === i18n.t(lastTech))
          const index = technicals.indexOf(found)

          if (index >= 0 && found !== undefined) {
            technicals[index].value = technicals[index].value + ', ' + i18n.t(meta.name)
          }
        } else {
          let value = null
          if (parent.i18nName === 'metas.annee') {
            value = i18n.t('metas.annee.val', {date: meta.name})
          } else {
            value = meta.name
          }
          technicals.push({
            code: parent.i18nName,
            label: i18n.t(parent.name),
            value: value
          })
        }

        lastTech = parent.name;
      })

      return technicals
    },
    async getProductInfos({dispatch, commit, rootGetters, rootState}, {product, i18n, api}) {
      let category = {...product.catalogs, id: product.catalogId};
      let lowerDeliveryPrice = null;
      let size = null;
      let state = null;
      let stateColor = null;

      const path = getCatalogPath(product.catalogs)

      const packagePrice = await dispatch('config/configSearch', {
        parent: 'packagesTransportersPrices',
        id: product.packageId
      }, {root: true});

      let minPrice = 9999;

      const availableTransporters = [...packagePrice.availableTransporters]

      if (product.customTransporter?.priceTTC?.length) {
        const customPriceTTC = getTransporterPrice(product.customTransporter, product.user.countryFrom, rootState.auth, rootGetters["config/getConfig"]);

        availableTransporters.push({
          ...product.customTransporter,
          custom: true,
          name: customPriceTTC?.label,
          description: customPriceTTC?.description
        })
      }

      for (const transporter of availableTransporters) {
        if (product.transportersIds.includes(transporter.id)) {
          if (transporter.id === COCOLIS_TRANSPORTER) {
            transporter.priceTTC = await dispatch('config/getCocolisPrice', {productId: product.id}, {root: true})
          }
          const {price, isImpossible} = getTransporterPrice(transporter, product.user.countryFrom, rootState.auth, rootGetters["config/getConfig"]);

          if (transporter.id !== HAND_TO_HAND_TRANSPORTER && price && !isImpossible) {
            minPrice = Math.min(minPrice, price);
          }
        }
      }

      lowerDeliveryPrice = minPrice !== 9999 ? minPrice : null;

      const productState = await dispatch('config/configSearch', {
        parent: 'states',
        id: parseInt(product.stateId)
      }, {root: true});

      const productSize = await dispatch('config/configSearch', {
        parent: 'sizes',
        id: parseInt(product.sizeId)
      }, {root: true});

      state = i18n.t(productState.name);
      stateColor = "text-state-" + productState.position;
      size = productSize ? i18n.t(productSize.name) : null;

      const transporterHandToHand = availableTransporters.filter(transporter => transporter.id === 4)[0];

      const transporters = getLocalisedTransporters(
        availableTransporters.filter(transporter => product.transportersIds.includes(transporter.id)),
        transporterHandToHand,
        product.user.countryFrom,
        rootState.auth,
        rootGetters["config/getConfig"],
      );

      const services = getProductServicesForTransporters(product, transporters)

      const generals = [];

      const gender = rootGetters["config/getConfig"].gendersProducts.find(gender => gender.id === product.gender);
      if (gender) {
        generals.push({
          code: 'gender',
          label: i18n.t('labels.gender'),
          value: gender.name
        });
      }
      generals.push({
        code: 'brand',
        label: i18n.t('labels.brand'),
        value: product.brand.name
      });

      if (productSize) {
        generals.push({
          code: 'size',
          label: i18n.t('labels.size'),
          value: productSize.name
        });
      }

      generals.push({
        code: 'state',
        label: i18n.t('labels.state'),
        value: productState.name
      });

      generals.push({
        code: 'colors',
        label: i18n.t('labels.colors'),
        value: product.colorsIds.map(color => {
          const colorFound = rootGetters["config/getConfig"].colors.find(c => c.id === parseInt(color));
          return colorFound ? colorFound.name : '';
        }).join(', ')
      });


      //const gender = this.config.gendersProducts.find(gender => gender.id === this.product.gender);


      let stateDetails = []
      if (product.is_under_guarantee) stateDetails.push({
        code: 'is_under_guarantee',
        value: i18n.t('product.underGuarantee')
      })
      if (product.is_never_use) stateDetails.push({
        code: 'is_never_use',
        value: i18n.t('product.neverUse')
      })
      if (product.is_perfect_state) stateDetails.push({
        code: 'is_perfect_state',
        value: i18n.t('product.perfectState')
      })
      let numberOfUse = product.nb_used
      if (product.nb_used > 0) {
        if (product.nb_used > 100) {
          stateDetails.push({
            code: 'nb_used',
            value: i18n.tc('product.numberOfUse.greater')
          })
        } else {
          stateDetails.push({
            code: 'nb_used',
            value: i18n.tc('product.numberOfUse', numberOfUse, {number: numberOfUse})
          })
        }

      }

    return {
      generals,
      path,
      transporters,
      stateDetails,
      services,
      size,
      state,
      stateColor,
      productState,
      category,
      lowerDeliveryPrice
    }
  },
  }
