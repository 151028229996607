import {SplashScreen} from '@capacitor/splash-screen';

export const state = () => ({
  history: [],
  nuxtReady: false,
  redirect: null,
  toasts: [],
  messageToRead: 0,
  notificationToRead: 0,
  backHome : true
})

export const mutations = {
  setMessageToRead(state, value) {
    state.messageToRead = value;
  },
  setNotificationToRead(state, value) {
    state.notificationToRead = value;
  },
  clearToast(state) {
    const copy = [...state.toasts];
    copy.shift();
    state.toasts = copy;
  },
  setToast(state, value) {
    state.toasts = [...state.toasts, {...value, id: Date.now()}];
  },
  setNuxtReady(state, value) {
    state.nuxtReady = value;
  },
  clearHistory(state, value) {
    state.history = [{path : '', back : false, depth : 0}];
  },
  setBackHome(state, value) {
    state.backHome = value;
  },
  setHistory(state, value) {
    state.history = value;
  },
  setRedirect(state, value) {
    state.redirect = value;
  }
}

export const getters = {
  isAuthenticated(state) {
    return state.auth.loggedIn
  },
  messageToRead(state) {
    return state.messageToRead;
  },
  notificationToRead(state) {
    return state.notificationToRead;
  },
  getRedirect(state) {
    return state.redirect;
  },
  getHistory(state) {
    return state.history;
  },
  getToasts(state) {
    return state.toasts;
  },
  getBackHome(state) {
    return state.backHome;
  },
  isNuxtReady(state) {
    return state.nuxtReady
  }
}

const coreLoad = async ({$api, commit, dispatch}) => {
  try {
    await dispatch('nav/fetchLightNav')
    await dispatch('config/fetchConfig')

  } catch (err) {
    console.error(err)
  }

  if (!process.server) {
    window.onNuxtReady(async () => {
      await SplashScreen.hide();
    })
  }
}

export const actions = {
  async nuxtIsReady({commit}) {
    commit('setNuxtReady', true);
  },
  async openForm({rootState, commit}, {data, uid, type, component, width, forceHeight, clickOutside, callback}) {
    if (rootState.auth.loggedIn
        && rootState.auth.user?.cgu && rootState.auth.user?.has_phone_number
        && component === 'login'
        && data?.step !== 'ForgotPassword'
        && data?.step !== 'ResetPassword')
      return
    commit('overlayForm/type', type || 'default');
    commit('overlayForm/component', component);
    commit('overlayForm/callback', callback);
    commit('overlayForm/data', data);
    commit('overlayForm/busId', uid);
    commit('overlayForm/size', width || 'content');
    commit('overlayForm/forceHeight', forceHeight);
    commit('overlayForm/clickOutside', clickOutside !== false);
  },
  async popHistory({state, commit}) {
    const old = [...state.history];
    old.pop();
    const back = old.pop();
    commit('setHistory', old);
    return back ? back : null;
  },
  async nuxtServerInit({state, commit, app, dispatch}, {req, $api}) {
    await coreLoad({$api, commit, dispatch});

  },

}
