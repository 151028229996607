export const state = () => ({
  nav: [],
  flattenNav: [],
})

export const mutations = {
  setNav(state, value) {
    state.nav = value;
    let flatten = [];
    for (let i = 0; i < value.length; i++) {
      flatten.push({name: value[i].name, id: value[i].id, slug: value[i].slug, level: 0, url: value[i].url});

      if (value[i].children) {
        for (let j = 0; j < value[i].children.length; j++) {
          flatten.push({
            name: value[i].children[j].name,
            id: value[i].children[j].id,
            slug: value[i].children[j].slug,
            url: value[i].children[j].url,
            level: 1,
            parent: value[i].label
          });
          for (let k = 0; k < value[i].children[j].children.length; k++) {
            flatten.push({
              name: value[i].children[j].children[k].name,
              id: value[i].children[j].children[k].id,
              slug: value[i].children[j].children[k].slug,
              url: value[i].children[j].children[k].url,
              level: 2,
              parent: value[i].label/* + '/' + value[i].children[j].label*/
            });
          }
        }
      }
    }
    state.flattenNav = Object.freeze(flatten);
  },
}

export const getters = {
  getNav(state) {
    return state.nav;
  },
}

export const actions = {
  async fetchLightNav({state, commit}) {
    const nav = await this.$api.$get(process.env.apiUrl + '/api/v2/config/catalogs/home', {}, {credentials: false})
    if (nav) {
      await commit('setNav', nav);
    }
  },
  async fetchNav({state, commit}) {
    const nav = await this.$api.$get(process.env.apiUrl + '/api/v2/config/catalogs/', {}, {credentials: false})

    if (nav) {
      await commit('setNav', nav);
    }
  },
  searchByString({state}, search) {
    const s = state.flattenNav.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
    let count = 0;
    let parent = null;

    const truncated = [];

    s.forEach((item, index) => {
      if (item.parent && item.parent !== parent) {
        count = 0;
        parent = item.parent;
      }

      if (count < 2) {
        truncated.push(item);
      }
      count++;

    })
    return truncated;
  },
  searchByUrl({state}, url) {
    const nav = state.nav;

    let path = [];

    let found = false;

    for (let i = 0; i < nav.length; i++) {
      path.length = 0;
      path[0] = nav[i];
      if (nav[i].url === url) {
        found = true;
        break;
      }
      if (nav[i]?.children) {
        for (let j = 0; j < nav[i]?.children?.length; j++) {
          path.length = 1;
          path[1] = nav[i]?.children[j];
          if (nav[i].children[j].url === url) {
            found = true;
            break;
          }
          if (!found) {
            for (let k = 0; k < nav[i]?.children[j]?.children?.length; k++) {
              path[2] = nav[i]?.children[j]?.children[k];
              if (nav[i].children[j].children[k].url === url) {
                found = true;
                break;
              }
            }
            if (found) {
              break;
            }
          }
        }
      }
      if (found) {
        break;
      }
    }

    return found ? path : [];
  },
  searchBySlug({state}, slug) {
    const nav = state.nav;
    let path = [];

    let found = false;

    for (let i = 0; i < nav.length; i++) {
      path.length = 0;
      path[0] = nav[i];
      if (nav[i].slug === slug) {
        found = true;
        break;
      }
      for (let j = 0; j < nav[i].children.length; j++) {
        path.length = 1;
        path[1] = nav[i].children[j];
        if (nav[i].children[j].slug === slug) {
          found = true;
          break;
        }
        if (!found) {
          for (let k = 0; k < nav[i].children[j].children.length; k++) {
            path[2] = nav[i].children[j].children[k];
            if (nav[i].children[j].children[k].slug === slug) {
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
      }
      if (found) {
        break;
      }
    }

    return found ? path : [];
  },
  searchByID({state}, id) {
    const nav = state.nav;
    let path = [];
    let found = false;
    for (let i = 0; i < nav.length; i++) {
      path.length = 0;
      path[0] = nav[i];
      if (nav[i].id === id) {
        found = true;
        break;
      }

      for (let j = 0; j < nav[i].children?.length; j++) {
        path.length = 1;
        path[1] = nav[i].children[j];
        if (nav[i].children[j].id === id) {
          found = true;
          break;
        }
        if (!found) {
          for (let k = 0; k < nav[i].children[j].children.length; k++) {
            path[2] = nav[i].children[j].children[k];
            if (nav[i].children[j].children[k].id === id) {
              found = true;
              break;
            }
          }
          if (found) {
            break;
          }
        }
      }
      if (found) {
        break;
      }
    }
    return path;
  }
}
