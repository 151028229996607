import {BRAND_TYPES} from "assets/js/constants/constant";
export const state = () => ({
  brands: [],
  partnerBrands: [],
  highLightedBrands: {
    air: [],
    bike: [],
    water: [],
    default: []
  },
  bestBrands: [],
  durableBrands: [],
})

export const mutations = {
  setBrands(state, value) {
    const orderedBrands = value.sort((a, b) => {
      if (a.highlighted > b.highlighted) {
        return -1;
      }
      if (a.highlighted < b.highlighted) {
        return 1;
      }
      return 0;
    });
    state.brands = Object.freeze(orderedBrands);
  },
  setPartnerBrands(state, value) {
    state.partnerBrands = Object.freeze(value);
  },
  setBestBrands(state, value) {
    state.bestBrands = Object.freeze(value);
  },
  setHighlightedBrands(state, value) {
    switch(value.type) {
      case BRAND_TYPES.air:
        state.highLightedBrands.air = Object.freeze(value.data);
        break;
      case BRAND_TYPES.water:
        state.highLightedBrands.water = Object.freeze(value.data);
        break;
      case BRAND_TYPES.bike:
        state.highLightedBrands.bike = Object.freeze(value.data);
        break;
      default:
        state.highLightedBrands.default = Object.freeze(value.data);
    }
  },
  setDurableBrands(state, value) {
    state.durableBrands = Object.freeze(value);
  },
}

export const getters = {
  getBrands(state) {
    return state.brands;
  },
  getHighLightedBrands(state) {
    return state.highLightedBrands;
  },
  getDurableBrands(state) {
    return state.durableBrands;
  },
  getPartnerBrands(state) {
    return state.partnerBrands;
  },
  getBestBrands(state) {
    return state.bestBrands;
  },
}

export const actions = {
  async fetchNavBrands({state, commit, getters}) {
    if (!(getters.getBestBrands.length && getters.getPartnerBrands.length && getters.getDurableBrands.length)) {
      const brands = await this.$api.$get(process.env.apiUrl + '/api/v2/brands/home');

      if (brands && !brands.error) {
        await commit('setDurableBrands', brands.filter(brand => brand.mostDurable === 1));
        await commit('setBestBrands', brands.filter(brand => brand.bestSeller === 1));
        await commit('setPartnerBrands', brands.filter(brand => brand.partner === 1));
      }
    }
  },

  async fetchBrands({state, commit, getters}, params) {
    let brands
    if (params?.type) {
      if (!getters.getHighLightedBrands[params.type]?.length) {
        brands = await this.$api.$get(process.env.apiUrl + '/api/v2/brands/search',{params: {type: params?.type ==='default' ? 'highlighted' : params?.type}});
      }
    } else {
      if (!getters.getBrands.length) {
        brands = await this.$api.$get(process.env.apiUrl + '/api/v2/brands/search');
      }
    }

    if (brands && !brands.error) {
      if (params?.type) {
        await commit('setHighlightedBrands', brands);
      } else {
        await commit('setBrands', brands.data);
      }
    }
  },

}
