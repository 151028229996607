import {SplashScreen} from '@capacitor/splash-screen';

export const state = () => ({
  config: {},
  build: null
})

export const mutations = {
  setConfig(state, value) {
    if(value.metas) {
      value.metas.forEach(meta => {
        if (meta.children) {
          try {
            meta.children.sort((a, b) => {
              return a.position < b.position ? -1 : 1;
            });
          } catch (e) {

          }
        }
      })
    }
    if (Object.keys(state.config).length) {
      state.config = Object.freeze({...state.config, ...value});
    } else {
      state.config = Object.freeze(value);
    }
  },
  setBuild(state, value) {
    state.build = value
  }
}

export const getters = {
  getConfig(state) {
    return state.config;
  },
  getBuild(state) {
    return state.build;
  }
}

export const actions = {


  async fetchConfigAddons({state, commit}) {
    const config = await this.$api.$get(process.env.apiUrl + '/api/v2/config/addons')
    if (config) {
      await commit('setConfig', config);
    }
  },

  async fetchConfig({state, commit}) {
    if (this.$capacitor) {
      const versionInfos = await this.$capacitor.getVersion();
      await commit('setBuild', versionInfos)
    }

    const config = await this.$api.$get(process.env.apiUrl + '/api/v2/config/global')
    if (config) {
      await commit('setConfig', config);
    }
  },

  async getCocolisPrice(_, {productId}) {
    const cocolisPrice = await this.$api.$get(process.env.apiUrl + '/api/v1/products/' + productId + '/ride')
    if (cocolisPrice.priceTTC?.price === 0) {
      cocolisPrice.priceTTC.isImpossible = true
    }

    return [cocolisPrice.priceTTC]
  },

  configSearch({state}, {parent, id, idKey = 'id'}) {
    if (state.config[parent]) {
      let found = null;
      state.config[parent].forEach(parentItem => {

        let item;

        if (parentItem.children) {
          item = parentItem.children.find(item => {
            return item[idKey] === id;
          });
        } else if (parentItem[idKey] === id) {
          item = parentItem;
        }

        if (item) {
          found = item;
        }
      });
      return found;
    } else {
      return null;
    }
  },
}
