export const state = () => ({
  ip: null,
  showNotificationSystemPopin: false,
  address : null,
  country: {
    isoCode: 'FR',
    countryCode: 33
  },
  wallet: '0,00 €'
});

export const mutations = {
  ip(state, value) {
    state.ip = value;
  },
  address(state, value) {
    state.address = value;
  },
  setWallet(state, value) {
    state.wallet = value;
  },
  showNotificationSystemPopin(state, value) {
    state.showNotificationSystemPopin = value;
  },
}

export const getters = {
  ip(state) {
   return state.ip;
  },
  wallet(state) {
    return state.wallet;
  },
  showNotificationSystemPopin(state) {
    return state.showNotificationSystemPopin;
  },
}
